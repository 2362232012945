import React from 'react';

import styled, { css } from 'styled-components';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
  SIZE_FONT_WEIGHT_DATA,
  SIZE_FONT_WEIGHT_ENUM,
} from '../../theme/size';
import { COLOR_ENUM } from '../../theme/color';
import { Spacing } from '../../theme';
import { TextElem } from '../text';
import { TAB_ELEM_TYPE } from './constant';
import { FONT_FAMILY_ENUM } from '../../theme/font';

export const Elem: React.FC<{
  tabList: TAB_ELEM_TYPE[];
  active?: number;
  isFullWith?: boolean;
  setActive?: Function;
}> = ({ tabList, active, setActive = () => {}, isFullWith = true }) => {
  const isActive = (value: number) => active === value;

  const handleSetActive = (value: number) => {
    if (value !== active) {
      setActive(value);
    }
  };

  return (
    <Container>
      {tabList.map((item: TAB_ELEM_TYPE) => (
        <TabItem
          key={item.value}
          isFullWith={isFullWith}
          active={isActive(item.value)}
          onClick={() => handleSetActive(item.value)}
        >
          <TextElem
            tid={item.tid}
            color={isActive(item.value) ? 'default' : 'textSecondary'}
            size="tab"
            type={isActive(item.value) ? 'medium' : 'regular'}
          />
        </TabItem>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${Spacing(3)};

  padding: ${Spacing(1)};
  background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_SECONDARY]};

  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  gap: ${Spacing(1)};

  @media screen and (max-width: 1110px) {
    grid-gap: ${Spacing(2)};
    overflow-x: auto;
  }
`;

export const TabItem = styled.div<{ active: boolean; isFullWith?: boolean }>`
  cursor: pointer;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  padding: 14px;
  max-height: 41px;
  line-height: 0.8em;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: ${({ isFullWith }) => (isFullWith ? '100%' : '180px')};
  // width: 100%;
  transition: all 0.2s;

  min-width: 120px;

  @media screen and (max-width: 900px) {
    min-width: 180px;
  }

  span {
    height: 14.5px;
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.ALERT]}px;
    font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.SEMI_BOLD]};
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PLACEHOLDER]};
  }

  ${({ active }) =>
    active
      ? css`
          border: unset !important;
          background: ${({ theme }) => theme[COLOR_ENUM.TAB_SECONDARY]};
        `
      : css`
          :hover {
            & > span {
              color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PRIMARY]};
            }
          }
        `};
`;
