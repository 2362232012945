import React from 'react';

import styled, { css } from 'styled-components';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
  SIZE_FONT_WEIGHT_DATA,
  SIZE_FONT_WEIGHT_ENUM,
} from '../../theme/size';
import { COLOR_ENUM } from '../../theme/color';
import { Spacing } from '../../theme';
import { TextElem } from '../text';
import { TAB_ELEM_TYPE } from './constant';
import { FONT_FAMILY_ENUM } from '../../theme/font';

export const Elem: React.FC<{
  tabList: TAB_ELEM_TYPE[];
  active?: number;
  setActive?: Function;
  maxWidth?: string;
}> = ({ maxWidth, tabList, active, setActive = () => {} }) => {
  const isActive = (value: number) => active === value;

  return (
    <Container maxWidth={maxWidth}>
      {tabList.map((item: TAB_ELEM_TYPE) => (
        <TabItem
          active={isActive(item.value)}
          onClick={() => setActive(item.value)}
        >
          <TextElem
            tid={item.tid}
            color={isActive(item.value) ? 'default' : 'textSecondary'}
            size="tab"
            type={isActive(item.value) ? 'medium' : 'regular'}
          />
        </TabItem>
      ))}
    </Container>
  );
};

const Container = styled.div<{ maxWidth?: string }>`
  display: flex;
  align-items: center;
  flex: 1 1 1 1 1;

  // margin-top: ${Spacing(3)};

  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : undefined)};

  padding: ${Spacing(1)};
  background: ${({ theme }) => theme[COLOR_ENUM.WHITE]};

  border-radius: 9px;
  gap: ${Spacing(1)};
`;

export const TabItem = styled.div<{ active: boolean }>`
  // width: 15%;
  // flex-basis: auto;
  // flex-shrink: 1;
  cursor: pointer;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
  padding: 14px;
  max-height: 41px;
  line-height: 0.8em;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  // width: 100%;
  transition: all 0.2s;

  span {
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.ALERT]}px;
    font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.SEMI_BOLD]};
    color: ${({ theme }) => theme[COLOR_ENUM.ICON_THIRD]};
  }

  ${({ active }) =>
    active
      ? css`
          border: unset !important;
          background: ${({ theme }) => theme[COLOR_ENUM.MENU_TAB_HOVER]};
          span {
            color: ${({ theme }) => theme[COLOR_ENUM.DEFAULT]};
          }
        `
      : css`
          :hover {
            & > span {
              color: ${({ theme }) => theme[COLOR_ENUM.DEFAULT]};
            }
          }
        `};
`;
