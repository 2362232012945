import React from 'react';

import { RouteComponentProps } from 'react-router';

import { AccountSettingsChangeEmailContainer } from '../../epic/account-settings-change-email';
import { AccountSettingsChangePasswordContainer } from '../../epic/account-settings-change-password';
import { LayoutSectionElem } from '../../common/layout-section';
import { LayoutFooterElem } from '../../common/layout-footer';
import { LayoutElem } from '../../common/layout';
import { PageElem } from '../../common/page';
// import { AccountSettingsChangeNameContainer } from '../../epic/account-settings-change-name';
import { DesktopMenuAppContainer } from '../../epic/desktop-menu-app';
import { HeaderContainer } from '../../epic/header';
import { AccountLogoutContainer } from '../../epic/account-logout';
import styled from 'styled-components';
import { LayoutAppSettingsElem } from '../../common/layout-app-settings';
import { TextElem } from '../../common/text';
import { MobileMenuContainer } from '../../epic/mobile-menu';
import { LayoutAppElem } from '../../common/layout-app';
import { GridElem } from '../../common/grid';
import { Spacing } from '../../theme';

export const Page: React.FC<RouteComponentProps> = () => {
  return (
    <PageElem>
      {/* <LayoutAppSettingsElem> */}
      <LayoutAppElem>
        <HeaderContainer
          title="PROJECT.LIST.HEADER"
          size="small"
        />
        {/* <SpacingStyled> */}
        <LayoutElem size="small">
          <LayoutSectionElem spacing={8}>
            <GridStyled>
              {/* <TextElem
                  tid="PROJECT.LIST.HEADER"
                  size="headerLogo"
                  color="textLink"
                  type="bold"
                /> */}
              <AccountSettingsChangePasswordContainer />
              <AccountSettingsChangeEmailContainer />
              <AccountLogoutContainer />
            </GridStyled>

            {/* <AccountSettingsChangeNameContainer /> */}
          </LayoutSectionElem>
        </LayoutElem>
        {/* </SpacingStyled> */}
      </LayoutAppElem>

      {/* </LayoutAppSettingsElem> */}
      <DesktopMenuAppContainer />
      <LayoutFooterElem>
        <MobileMenuContainer />
      </LayoutFooterElem>
    </PageElem>
  );
};

const GridStyled = styled(GridElem)`
  gap: ${Spacing(8)};
`;

const SpacingStyled = styled.div`
  display: grid;
  max-width: 70%;
`;
