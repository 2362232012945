import { USER_ROLE } from '../../data/auth/constant';
import { ACCOUNT_UPDATE_PAGE_PATH } from '../../page/account-profile-update';
import { CONSULTATION_LIST_PAGE_PATH } from '../../page/consultation-list';
import { PAYMENT_PAGE_PATH } from '../../page/payment';
import { PSYCHO_LIST_PAGE_PATH } from '../../page/psycho-list';
import { SCHEDULE_PAGE_PATH } from '../../page/schedule';
import { USER_PAGE_PATH } from '../../page/user';

import noteBordIcon from '../../asset/svg/menu-app/notebord.svg';
import noteBordActiveIcon from '../../asset/svg/menu-app/notebord-active.svg';

import userIcon from '../../asset/svg/menu-app/user.svg';
import userActiveIcon from '../../asset/svg/menu-app/user-active.svg';

import paymentIcon from '../../asset/svg/menu-app/payment.svg';
import paymentActiveIcon from '../../asset/svg/menu-app/payment-active.svg';

import calendarIcon from '../../asset/svg/menu-app/calendar.svg';
import calendarActiveIcon from '../../asset/svg/menu-app/calendar-active.svg';

import psychoIcon from '../../asset/svg/menu-app/psycho-list.svg';
import psychoActiveIcon from '../../asset/svg/menu-app/psycho-list-active.svg';

export const MODULE_NAME = 'DESKTOP_MENU_APP_MODULE_NAME';

export const getMenuItems = (userRole: USER_ROLE) => [
  {
    title: 'NAVIGATION.TAB_APP.CONSULTATION_LIST',
    icon: calendarIcon,
    iconActive: calendarActiveIcon,
    path: CONSULTATION_LIST_PAGE_PATH,
    isVisible: true,
  },
  {
    title: 'NAVIGATION.TAB_APP.CARETAKER_LIST',
    icon: psychoIcon,
    iconActive: psychoActiveIcon,
    path: PSYCHO_LIST_PAGE_PATH,
    isVisible: userRole === USER_ROLE.USER,
  },
  // {
  //   title: 'NAVIGATION.TAB_APP.PAYMENT',
  //   icon: paymentIcon,
  //   iconActive: paymentActiveIcon,
  //   path: PAYMENT_PAGE_PATH,
  //   isVisible: userRole === USER_ROLE.ADMIN,
  // },
  {
    title: 'NAVIGATION.TAB_APP.USER',
    icon: userIcon,
    iconActive: userActiveIcon,
    path: USER_PAGE_PATH,
    isVisible: userRole === USER_ROLE.ADMIN,
  },
  {
    title: 'NAVIGATION.TAB_APP.SCHEDULE',
    icon: calendarIcon,
    iconActive: calendarActiveIcon,
    path: SCHEDULE_PAGE_PATH,
    isVisible: userRole === USER_ROLE.CARETAKER,
  },
  {
    title: 'NAVIGATION.TAB_APP.ACCOUNT_UPDATE',
    icon: noteBordIcon,
    iconActive: noteBordActiveIcon,
    path: ACCOUNT_UPDATE_PAGE_PATH,
    isVisible: userRole === USER_ROLE.USER || userRole === USER_ROLE.CARETAKER,
  },
];
