import React, { ReactNode } from 'react';

import styled from 'styled-components';

import { IonFooter } from '@ionic/react';
import { SIZE_LAYOUT_DATA, SIZE_LAYOUT_ENUM } from '../../theme/size';
import { Spacing } from '../../theme';
import { COLOR_ENUM } from '../../theme/color';

export const Elem: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  return (
    <Footer>
      <Container>{children}</Container>
    </Footer>
  );
};

const Footer = styled(IonFooter)`
  background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
  ::before {
    content: none;
  }
  border-top: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]};

  @media (min-width: 901px) {
    display: none !important;
  }
`;

const Container = styled.div`
  margin: 0 auto;
  padding: 19px ${Spacing(4)};

  display: grid;
  gap: ${Spacing(4)};
`;
