import styled from 'styled-components';
import { COLOR_ENUM } from '../../../theme/color';
import { Spacing } from '../../../theme';
import { TextElem } from '../../../common/text';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
} from '../../../theme/size';
import {
  PSYCHOLOGIST_ITEM_DATA_INTER,
  USER_ITEM_DATA_RAW_INTER,
  USER_ROLE,
} from '../../../data/user/constant';
import { CATEGORY_ENUM } from '../../profile-psycho-create-category/constant';
import { ReadMoreElem } from '../../../common/read-more';
import { ReadMoreListElem } from '../../../common/read-more-list';
import { FlexElem } from '../../../common/flex';
import { USER_ROLE_VIEW } from '../constant';

export const ClientContainer: React.FC<{
  caretaker: PSYCHOLOGIST_ITEM_DATA_INTER;
  user: USER_ITEM_DATA_RAW_INTER;
  role: USER_ROLE;
  comment: string;
  category: CATEGORY_ENUM;
}> = ({ user, caretaker, comment, category, role }) => {
  const roleText =
    role != USER_ROLE.USER
      ? USER_ROLE_VIEW[USER_ROLE.USER]
      : USER_ROLE_VIEW[USER_ROLE.CARETAKER];

  console.log(user);

  const contactInfoRoles = {
    email: role == USER_ROLE.CARETAKER ? user.email : caretaker.email,
    phone: role == USER_ROLE.CARETAKER ? user.phone : caretaker.phone,
    contactInfo: role == USER_ROLE.CARETAKER ? user.contactInfo : null,
  };

  const userRequest = [
    user.specialistNeeded,
    user.needPlacement,
    user.needLanguage,
  ].filter(Boolean);
  const animalInfo = [
    user.animalName,
    user.animalSize,
    user.animalAge,
    user.vaccination,
    user.animalType,
  ].filter(Boolean);

  return (
    <Container>
      {/* <FlexStyled> */}
      <Block>
        <Title
          color="textPrimary"
          type="medium"
          size="label"
          lineHeight="1em"
          tid="CONSULTATION.CLIENT.NEED_CONSULTATION"
        />
        <Description
          color="textSecondary"
          size="semiSmall"
          lineHeight="1.5em"
          type="medium"
          tid={category as unknown as string}
        />
      </Block>
      <Block>
        <Title
          color="textPrimary"
          type="medium"
          size="label"
          lineHeight="1em"
          tid="CONSULTATION.CLIENT.COMMENT"
        />
        {comment && (
          <ReadMoreElem
            // color="textPrimary"
            // lineHeight="1.5em"
            textLimit={40}
            text={comment}
          />
        )}
        {!comment && (
          <Description
            type="medium"
            color="textSecondary"
            size="semiSmall"
            tid="USER.PSYCHO.PROFILE.NOT_SET"
          />
        )}
      </Block>
      {/* </FlexStyled> */}
      {/* <FlexStyled> */}
      <Block>
        <Title
          color="textPrimary"
          type="medium"
          size="label"
          tid="CONSULTATION.CLIENT.NEED_REQUEST"
        />
        <GridContainer>
          {userRequest.length > 0 ? (
            <>
              {user.specialistNeeded && (
                <TextContainer>
                  <TextElem
                    type="medium"
                    color="textButtonClick"
                    size="semiSmall"
                    tid="CONSULTATION.CLIENT.NEED_SPECIALIST"
                  />
                  <ReadMoreElem
                    textLimit={1}
                    text={[`${user.specialistNeeded}`]}
                  />
                </TextContainer>
              )}
              {user?.needPlacement && (
                <TextContainer>
                  <TextElem
                    type="medium"
                    color="textButtonClick"
                    size="semiSmall"
                    tid="CONSULTATION.CLIENT.NEED_PLACEMENT"
                  />
                  <ReadMoreElem
                    textLimit={1}
                    text={[`${user.needPlacement}`]}
                  />
                </TextContainer>
              )}

              {user.needLanguage && (
                <TextContainer className="language">
                  <TextElem
                    type="medium"
                    color="textButtonClick"
                    size="semiSmall"
                    tid="CONSULTATION.CLIENT.NEED_LANGUAGE"
                  />
                  <ReadMoreElem text={[...user.needLanguage]} />
                </TextContainer>
              )}
            </>
          ) : (
            <Description
              type="regular"
              color="textSecondary"
              size="input"
              tid="USER.PSYCHO.PROFILE.NOT_SET"
            />
          )}
        </GridContainer>
      </Block>
      <Block>
        <Title
          color="textPrimary"
          type="medium"
          size="label"
          tid="CONSULTATION.CLIENT.ANIMAL_INFO"
        />
        <GridContainer>
          {animalInfo.length > 0 ? (
            <>
              {user.animalType && (
                <TextContainer>
                  <TextElem
                    type="medium"
                    color="textButtonClick"
                    size="semiSmall"
                    tid="CONSULTATION.CLIENT.ANIMAL_TYPE"
                  />
                  <ReadMoreElem textLimit={1} text={[`${user.animalType}`]} />
                </TextContainer>
              )}
              {user.animalName && (
                <TextContainer>
                  <TextElem
                    type="medium"
                    color="textButtonClick"
                    size="semiSmall"
                    tid="CONSULTATION.CLIENT.ANIMAL_NAME"
                  />

                  <ReadMoreElem textLimit={1} text={[`${user.animalName}`]} />
                </TextContainer>
              )}
              {user.animalAge && (
                <TextContainer>
                  <TextElem
                    type="medium"
                    color="textButtonClick"
                    size="semiSmall"
                    tid="CONSULTATION.CLIENT.ANIMAL_AGE"
                  />

                  <ReadMoreElem textLimit={1} text={[`${user.animalAge}`]} />
                </TextContainer>
              )}
              {user.animalSize && (
                <TextContainer>
                  <TextElem
                    type="medium"
                    color="textButtonClick"
                    size="semiSmall"
                    tid="CONSULTATION.CLIENT.ANIMAL_SIZE"
                  />

                  <ReadMoreElem textLimit={1} text={[`${user.animalSize}`]} />
                </TextContainer>
              )}
              {user.vaccination && (
                <TextContainer>
                  <TextElem
                    type="medium"
                    color="textButtonClick"
                    size="semiSmall"
                    tid="CONSULTATION.CLIENT.ANIMAL_VACCINATION"
                  />
                  <ReadMoreElem textLimit={1} text={[`${user.vaccination}`]} />
                </TextContainer>
              )}
            </>
          ) : (
            <Description
              type="regular"
              color="textSecondary"
              size="input"
              tid="USER.PSYCHO.PROFILE.NOT_SET"
            />
          )}
        </GridContainer>
      </Block>
      {/* </FlexStyled> */}
      <Block className="contactBlock">
        <Title
          color="textPrimary"
          type="medium"
          size="label"
          tid="CONSULTATION.CLIENT.CONTACT_INFO"
          tvalue={{ role: roleText }}
        />
        <FlexStyled>
          {contactInfoRoles?.email && (
            <TextContainer>
              <TextElem
                type="medium"
                color="textButtonClick"
                size="semiSmall"
                tid="CONSULTATION.CLIENT.EMAIL"
              />
              <TextElem
                type="regular"
                size="main"
                color="textSecondary"
                tid={`${contactInfoRoles.email}`}
              />
            </TextContainer>
          )}
          {contactInfoRoles?.phone && (
            <TextContainer>
              <TextElem
                type="medium"
                color="textButtonClick"
                size="semiSmall"
                tid="CONSULTATION.CLIENT.PHONE"
              />
              <TextElem
                type="regular"
                size="main"
                color="textSecondary"
                tid={`${contactInfoRoles.phone}`}
              />
            </TextContainer>
          )}
          {contactInfoRoles?.contactInfo && (
            <TextContainer>
              <TextElem
                type="medium"
                color="textButtonClick"
                size="semiSmall"
                tid="CONSULTATION.CLIENT.CONTACT"
              />
              <TextElem
                type="regular"
                size="main"
                color="textSecondary"
                tid={`${contactInfoRoles.contactInfo}`}
              />
            </TextContainer>
          )}
        </FlexStyled>
      </Block>
    </Container>
  );
};

const FlexStyled = styled(FlexElem)`
  flex-wrap: wrap;
  // @media screen and (max-width: 570px) {
  //   flex-wrap: wrap;
  // }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: max-content;
  gap: ${Spacing(2)};

  p > div {
    margin-top: 0;
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${Spacing(4)};

  .language {
    grid-column: span 2;
  }
`;

const Dot = styled.div`
  height: 4px;
  width: 4px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  position: relative;
  top: 1px;
`;

const Title = styled(TextElem)`
  @media screen and (max-width: 768px) {
    font-size: 15px;
  }
`;

const Description = styled(TextElem)`
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr auto;
  row-gap: ${Spacing(4)};
  column-gap: ${Spacing(3)};

  .contactBlock {
    grid-column: 1 / -1;
  }

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: ${Spacing(3)};
  }
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  padding: 21px ${Spacing(6)};
  gap: ${Spacing(3)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.PROFILE_PHOTO
  ]}px;
  // width: 100%;
  height: 100%;

  .contactInfo {
    display: flex;
    gap: ${Spacing(3)};
  }

  @media screen and (max-width: 720px) {
    padding: ${Spacing(5)};
  }
`;
