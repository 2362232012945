import React, { useEffect } from 'react';
import styled from 'styled-components';

import { FormikValues } from 'formik';

import { FORM_VALUE_ENUM } from './constant';

import { GridElem } from '../../common/grid';
import { ButtonElem } from '../../common/button';
import { LoaderElem } from '../../common/loader';
import { AlertActionElem } from '../../common/alert-action';
import { FieldPasswordElem } from '../../common/field-password';
import { FormElem } from '../../common/form';
import { TextElem } from '../../common/text';
import { Spacing } from '../../theme';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_WEIGHT_ENUM,
} from '../../theme/size';
import { FieldTextElem } from '../../common/field-text';
import { LinkElem } from '../../common/link';
import { AUTH_LOGIN_PAGE_PATH } from '../../page/auth-login';
import parse from 'html-react-parser';
import { i18n } from '../../lib/lang';

export const Component: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  isLoading?: boolean;
  isError?: boolean;
  errorMessage?: string;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  isSubmitDisabled,
  getFieldValue,
  isLoading,
  isError,
  errorMessage,
}) => {
  useEffect(() => {
    console.log('Formik errors:', formik.errors);
    console.log('Formik touched:', formik.touched);
    console.log('Formik isValid:', formik.isValid);
    console.log('Formik dirty:', formik.dirty);
  }, [formik.errors, formik.touched, formik.isValid, formik.dirty]);

  return (
    <Form onSubmit={formik.handleSubmit}>
      {isLoading && <LoaderElem />}
      <GridStyled size="mod">
        <TextContainer>
          <TextElemStyled
            size="logo"
            color="textPrimary"
            type="bold"
            tid="AUTH.RECOVERY_PASSWORD.TITLE"
            fontFamily="Raleway"
          />

          <TextElemStyled
            size="default"
            color="textSecondary"
            type="regular"
            tid="AUTH.RECOVERY_PASSWORD.DESCRIPTION"
            fontFamily="Raleway"
          />
        </TextContainer>

        <GridElem size="input">
          <FieldTextElem
            name={FORM_VALUE_ENUM.EMAIL}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            title="AUTH.RECOVERY_PASSWORD.EMAIL"
            placeholder="AUTH.LOGIN.EMAIL_PLACEHOLDER"
            value={getFieldValue(FORM_VALUE_ENUM.EMAIL)}
            errorMessage={getFieldError(FORM_VALUE_ENUM.EMAIL)}
            error={isFieldError(FORM_VALUE_ENUM.EMAIL)}
            type="email"
          />
        </GridElem>

        <MessageContainer>
          <TextStyled tid="AUTH.RECOVERY_PASSWORD.QUESTION" />
          <TextLink link={AUTH_LOGIN_PAGE_PATH}>
            {parse(i18n.t('AUTH.RECOVERY_PASSWORD.ENTRY', new Object()))}
          </TextLink>
        </MessageContainer>

        <ButtonElem
          disabled={isSubmitDisabled()}
          type="submit"
          tid="AUTH.RECOVERY_PASSWORD.BUTTON"
          sizeText="main"
          fill="solid"
        />

        {isError && <AlertActionElem text={errorMessage} />}
      </GridStyled>
    </Form>
  );
};

const TextElemStyled = styled(TextElem)`
  text-align: center;
  line-height: 1.7em;
  white-space: nowrap;
`;

const GridStyled = styled(GridElem)`
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CONTENT]}px;
  max-width: 366px;
  width: 100%;
  gap: ${Spacing(3)};
  justify-items: center;

  @media (max-width: 490px) {
    max-width: 100%;
  }
`;

const Form = styled(FormElem)`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${Spacing(10)};
  max-width: 628px;
  align-items: center;
  width: 100%;

  @media (max-width: 490px) {
    padding: 0 16px;
    max-width: 100%;\
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const TextStyled = styled(TextElem)`
  font-family: Raleway;
  font-size: 14px;
  font-weight: ${SIZE_FONT_WEIGHT_ENUM.MEDIUM};
  line-height: 21px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #acb1bf;
`;

const TextLink = styled(LinkElem)`
  font-family: Raleway;
  font-size: 14px;
  font-weight: ${SIZE_FONT_WEIGHT_ENUM.MEDIUM};
  line-height: 21px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #1d202a;
  cursor: pointer;
`;

const MessageContainer = styled.div`
  display: flex;
  font-family: Raleway;
  font-size: 14px;
  font-weight: ${SIZE_FONT_WEIGHT_ENUM.SEMI_BOLD};
  line-height: 21px;
  letter-spacing: -0.01em;
  text-align: center;
  gap: 2px;
`;
