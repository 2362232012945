import { CATEGORY_ENUM } from '../../epic/profile-psycho-create-category/constant';
import { CONSULTATION_STATUS } from '../consultation/constant';

export enum PAYMENT_VALUTE {
  USD = 'USD',
  EUR = 'EUR',
  UAH = 'UAH',
  RUB = 'RUB',
}

export enum PAYMENT_STATUS {
  PAID = 'paid',
  NOT_PAID = 'not_paid',
}

export const PAYMENT_VALUTE_SYMBOL = {
  [PAYMENT_VALUTE.USD]: '$',
  [PAYMENT_VALUTE.EUR]: '€',
  [PAYMENT_VALUTE.UAH]: '$',
  [PAYMENT_VALUTE.RUB]: '$',
};

export enum PAYMENT_SYSTEM_TYPE {
  WAYFORPAY = 'WAYFORPAY',
  MANUAL = 'MANUAL',
}

export const paymentView = {
  [PAYMENT_SYSTEM_TYPE.WAYFORPAY]: 'Wayforpay',
  [PAYMENT_SYSTEM_TYPE.MANUAL]: 'Ручна',
};

export interface PAYMENT_ITEM_DATA_RAW_INTER {
  id: string;
  amount: number;
  valute: PAYMENT_VALUTE;
  liqpayPaid: boolean;
  isPaid: boolean;
  system: PAYMENT_SYSTEM_TYPE;
  manualPaid: boolean;
  confirm: boolean;
  updateDate: string;
  createDate: string;
  consultation: {
    id: string;
    category: CATEGORY_ENUM;
    status: CONSULTATION_STATUS;
    price: number;
    isFirst: boolean;
    date: string;
  };
  user: {
    email: string;
    id: string;
    name: string;
    phone: string;
  };
  psychologist: {
    email: string;
    id: string;
    name: string;
    bankName: string;
    accountNumber: string;
    phone: string;
  };
}

export interface PAYMENT_ITEM_LIST_DATA_RAW_INTER {
  list: PAYMENT_ITEM_DATA_RAW_INTER[];
}

export interface PAYMENT_ITEM_DATA_INTER
  extends Omit<PAYMENT_ITEM_DATA_RAW_INTER, 'valute'> {
  status?: string;
  valute: string;
  categoryView?: string;
  dateView?: string;
}

export interface PAYMENT_ITEM_LIST_DATA_INTER {
  list: PAYMENT_ITEM_DATA_INTER[];
  isEmpty: boolean;
}
