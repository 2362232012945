import React from 'react';

import { GridElem } from '../../common/grid';
import { TextElem } from '../../common/text';
import iconRing from '../../asset/svg/menu-app/ring.svg';
import styled, { css } from 'styled-components';
import { COLOR_ENUM } from '../../theme/color';
import { Spacing } from '../../theme';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_WEIGHT_DATA,
  SIZE_FONT_WEIGHT_ENUM,
} from '../../theme/size';

export const Component: React.FC<{
  isActive: boolean;
  onSubmitForm: any;
}> = ({ onSubmitForm, isActive }) => {
  return (
    <Container onClick={onSubmitForm} spacing={3} isActive={isActive}>
      <Icon className="icon" isActive={isActive} icon={iconRing} />
      <TextElem
        tid="SETTINGS.NOTIFICATION"
        color="iconDefault"
        type="semi-bold"
      />
    </Container>
  );
};

const Container = styled(GridElem)<{ isActive: boolean }>`
  padding: 17.5px;
  width: 100%;
  display: flex;
  gap: ${Spacing(3)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;
  align-items: center;
  position: relative;
  margin-top: auto;
  span {
    height: 15px;
  }

  cursor: pointer;

  // :hover {
  //   background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
  // }

  ${({ isActive }) => {
    if (isActive) {
      return css`
        background: ${({ theme }) => theme[COLOR_ENUM.MENU_TAB]};

        & > span {
          font-weight: ${SIZE_FONT_WEIGHT_DATA[
            SIZE_FONT_WEIGHT_ENUM.SEMI_BOLD
          ]};
          color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
        }

        .icon {
          background-color: ${({ theme }) =>
            theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
        }
      `;
    }
    if (!isActive) {
      return css`
        :hover {
          background: ${({ theme }) => theme[COLOR_ENUM.MENU_TAB_HOVER]};

          .icon {
            background-color: ${({ theme }) => theme[COLOR_ENUM.DEFAULT]};
          }
          & > span {
            color: ${({ theme }) => theme[COLOR_ENUM.DEFAULT]};
          }
        }
      `;
    }
  }};
`;

const Icon = styled.div<{ icon: string; isActive?: boolean }>`
  height: 18px;
  width: 18px;
  ${({ icon }) => css`
    -webkit-mask: url(${icon}) no-repeat center;
    mask: url(${icon}) no-repeat center;
    z-index: 1;
    background-color: ${({ theme }) => theme[COLOR_ENUM.ICON_DEFAULT]};
  `}

  ${({ isActive }) =>
    isActive
      ? css`
          background-color: ${({ theme }) => theme[COLOR_ENUM.LOGIN]};
        `
      : ''};
`;
