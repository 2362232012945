import React from 'react';

import styled from 'styled-components';
import { AlertActionElem } from '../../common/alert-action';

import { GridElem } from '../../common/grid';

import { Spacing } from '../../theme';
import { AlertEmptyElem } from '../../common/alert-empty';

import { COLOR_ENUM } from '../../theme/color';
import { TextElem } from '../../common/text';
// import { IonInfiniteScroll } from '@ionic/react';
import { SkeletonListElem } from '../../common/skeleton-list';
import {
  CONSULTATION_ITEM_DATA_INTER,
  CONSULTATION_ITEM_LIST_DATA_INTER,
} from './constant';
import { Card } from './frame/card';

export const Component: React.FC<{
  data?: CONSULTATION_ITEM_LIST_DATA_INTER;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  isFetching?: boolean;
  fetchNextPage: Function;
  isIdle?: boolean;
}> = ({
  data,
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  isFetching,
  fetchNextPage,
  isIdle,
}) => {
  return (
    <GridElem spacing={5}>
      {isError && <AlertActionElem text={errorMessage} />}
      <Container>
        {data && isSuccess && data.isEmpty && <AlertEmptyElem />}

        <GridStyled>
          {data && !data.isEmpty && (
            <>
              <Head>
                <TextElem
                  tid="CONSULTATION.LIST.HEAD.NAME"
                  color="textSecondary"
                  size="semiSmall"
                  type="medium"
                />
                <TextElem
                  tid="CONSULTATION.LIST.HEAD.CATEGORY"
                  color="textSecondary"
                  size="semiSmall"
                  type="medium"
                />
                <TextElem
                  tid="CONSULTATION.LIST.HEAD.DATE"
                  color="textSecondary"
                  size="semiSmall"
                  type="medium"
                />
                <TextElem
                  tid="CONSULTATION.LIST.HEAD.DURATION"
                  color="textSecondary"
                  size="semiSmall"
                  type="medium"
                />
                <TextElem
                  tid="CONSULTATION.LIST.HEAD.PAYMENT_STATUS"
                  color="textSecondary"
                  size="semiSmall"
                  type="medium"
                />
              </Head>
              {isSuccess &&
                !isFetching &&
                data?.list.map((item: CONSULTATION_ITEM_DATA_INTER) => (
                  <Card
                    key={`${item.id}-${item?.psychologist?.name}-${item?.user?.name}`}
                    {...item}
                  />
                ))}
            </>
          )}
        </GridStyled>
        {isFetching && !data?.isEmpty && (
          <SkeletonWrapper>
            <SkeletonListElem />
          </SkeletonWrapper>
        )}
        {/* {data &&
          data.list &&
          data.list.length > 39 &&
          !data.isEmpty &&
          !isFetching && (
            <IonInfiniteScroll
              onIonInfinite={(ev) => {
                fetchNextPage();
                setTimeout(() => ev.target.complete(), 500);
              }}
            >
              {!isIdle && <PaymentSkeletonElem />}
            </IonInfiniteScroll>
          )} */}
      </Container>
    </GridElem>
  );
};

const SkeletonWrapper = styled.div`
  margin-top: ${Spacing(4)};
  min-height: 600px;
  width: 100%;
`;

const Container = styled.div`
  /* overflow-x: auto; */
  scrollbar-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_THIRD]}
    transparent;
  scrollbar-width: thin;
  width: 100%;
`;

const GridStyled = styled(GridElem)`
  width: 100%;
  grid-auto-columns: max-content max-content max-content max-content auto;
  column-gap: ${Spacing(14)};

  @media screen and (max-width: 1024px) {
    column-gap: ${Spacing(12)};
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Head = styled(GridElem)`
  display: none;

  @media screen and (min-width: 1024px) {
    display: block;
    max-height: 56px;
    padding: ${Spacing(5)};
    align-items: center;
    gap: 0;
    gap: inherit;
    // justify-content: center;
    display: grid;
    grid-column: span 5;
    grid-template-columns: subgrid;
    // grid-template-columns: max-content max-content max-content max-content 1fr;

    // justify-content: start;
    border-radius: 14px;
    border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER_HOVER]};

    & > :last-child {
      margin-right: 12px;
      justify-self: end;
      text-align: center;
    }
  }
`;
