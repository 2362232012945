import React from 'react';
import styled from 'styled-components';

import { Spacing } from '../../theme';
import { SkeletonProfileElem } from '../../common/skeleton-profile';
import { AlertActionElem } from '../../common/alert-action';
import {
  CONSULTATION_DATA_INTER,
  CONSULTATION_STATUS,
} from '../../data/consultation/constant';
import { useSelector } from '../../lib/store';
import { AUTH_MODULE_NAME } from '../../data/auth';
import { ConsultationAdminStatusContainer } from '../consultation-admin-status';
import { ConsultationAdminReassignContainer } from '../consultation-admin-reassign';
import { BackHeaderElem } from '../../common/back-header';
import { InforamationContainer } from './frame/information';
import { UserHeader } from './frame/user-header';

export const Component: React.FC<{
  data: CONSULTATION_DATA_INTER;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  errorMessage: string;
}> = ({ data, isSuccess, isLoading, isError, errorMessage }) => {
  const { auth } = useSelector((s: any) => ({
    auth: s[AUTH_MODULE_NAME],
  }));

  const timezone = auth.user ? auth.user.timezone : null;
  const isCancel = data.status === CONSULTATION_STATUS.CANCEL;

  return (
    <>
      {isLoading && <SkeletonProfileElem />}
      {isError && <AlertActionElem text={errorMessage} />}

      {isSuccess && (
        <>
          <BackHeaderElem id={data.id} path='/consultation'/>
          <Container>
            <UserHeader {...data} />
            <InforamationContainer {...data} />
            <Double>
              <ConsultationAdminReassignContainer
                isPaid={data?.payment?.isPaid}
                consultationId={data?.id}
                userTimezone={timezone}
                clientTimezone={data?.user?.numberTimezone}
                psychologistId={data?.psychologist?.id}
                startDate={data?.date}
                status={data.status}
              />

              <ConsultationAdminStatusContainer
                consultationId={data.id}
                status={data.status}
              />
            </Double>
          </Container>
        </>
      )}
    </>
  );
};

const Container = styled.div`
  display: grid;
  grid-gap: ${Spacing(5)};
  margin-top: 38px;

  @media screen and (max-width: 1160px) {
    margin-bottom: ${Spacing(25)};
  }
`;

const Double = styled.div`
  display: flex;
  gap: ${Spacing(5)};

  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
`;
