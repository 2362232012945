import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';

import { PageElem } from '../../common/page/index';
import { LayoutAppElem } from '../../common/layout-app/index';
import { LayoutElem } from '../../common/layout/index';
import { LayoutSectionElem } from '../../common/layout-section/index';
import { ConsultationListContainer } from '../../epic/consultation-list/index';
import { HeaderContainer } from '../../epic/header/index';
import { LayoutFooterElem } from '../../common/layout-footer/index';
import { DesktopMenuAppContainer } from '../../epic/desktop-menu-app/index';
import { STORE_INTER, USER_ROLE } from '../../data/auth/constant';
import { AUTH_MODULE_NAME } from '../../data/auth/index';
import { ConsultationListAdminContainer } from '../../epic/consultation-list-admin/index';
// import { ConsultationListFilterContainer } from '../../epic/consultation-list-filter/index';
import { FILTER_OPTION } from '../../epic/consultation-list-admin/constant';
import { ConsultationListFilterContainer } from '../../epic/consultation-list-filter';
import { MobileMenuContainer } from '../../epic/mobile-menu';
import { GridElem } from '../../common/grid';
// import noteIcon from '../../asset/svg/menu-app/note.svg';

export const Page: React.FC = () => {
  const state: STORE_INTER = useSelector((s: any) => s[AUTH_MODULE_NAME]);
  const isAdmin = state.user?.role === USER_ROLE.ADMIN;
  const [filterOption, setFilterOption] = useState<FILTER_OPTION>();

  const setOption = (e: any) => {
    setFilterOption(e);
  };
  return (
    <>
      <PageElem>
        <LayoutAppElem>
          <HeaderContainer
            // icon={noteIcon}
            title="CONSULTATION.HEADER"
            size="default"
          />
          <LayoutElem size="default">
            <LayoutSectionElem spacing={5}>
              {!isAdmin && <ConsultationListContainer />}

              {isAdmin && (
                <GridElem spacing={5}>
                  <ConsultationListFilterContainer setOption={setOption} />
                  <ConsultationListAdminContainer filterOption={filterOption} />
                </GridElem>
              )}
            </LayoutSectionElem>
          </LayoutElem>
        </LayoutAppElem>
        <DesktopMenuAppContainer />
        <LayoutFooterElem>
          <MobileMenuContainer />
        </LayoutFooterElem>
      </PageElem>
    </>
  );
};
