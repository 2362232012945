import React, { ComponentProps } from 'react';
import styled from 'styled-components';
import { TextElem } from '../../common/text';
import { ButtonElem } from '../../common/button';
import { COLOR_DATA, COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
  SIZE_FONT_WEIGHT_DATA,
  SIZE_FONT_WEIGHT_ENUM,
} from '../../theme/size';
import { PaddingContainer } from '../home-footer/component';
import { Spacing } from '../../theme';
import { i18n } from '../../lib/lang';
import { FAQProps, question } from './constant';

export const Component: React.FC<FAQProps> = ({
  activeQuestion,
  toggleQuestion,
  activeIcon,
}) => {
  return (
    <BodyContainer>
      <AboutContainer>
        <AboutBlock>
          <AboutText>
            <AboutHeadTitle fontFamily="Raleway">
              <HeadTitle>
                <HeadTitlePrimary>
                  {i18n.t('LANDING.HOME_FAQ.TITLE')}
                </HeadTitlePrimary>

                <HeadTitleHighlight>
                  {i18n.t('LANDING.HOME_FAQ.SUBTITLE')}
                </HeadTitleHighlight>
              </HeadTitle>
            </AboutHeadTitle>

            <QuestionBlock>
              {question.map((q, index) => (
                <QuestionChildBlock
                  key={index}
                  onClick={() => toggleQuestion(index)}
                >
                  <QuestionTextGroup>
                    <QuestionTextMain tid={q.tid} />
                    {activeQuestion === index && (
                      <QuestionTextSecond>
                        {i18n.t(q.answerTid)}
                      </QuestionTextSecond>
                    )}
                  </QuestionTextGroup>
                  <ArrowImg
                    src={
                      activeQuestion === index
                        ? activeIcon
                        : './assets/svg/landing/arrow.svg'
                    }
                    alt="arrow"
                  />
                </QuestionChildBlock>
              ))}
            </QuestionBlock>
          </AboutText>
        </AboutBlock>
      </AboutContainer>
    </BodyContainer>
  );
};

const BodyContainer = styled(PaddingContainer)`
  background: ${COLOR_DATA[COLOR_ENUM.BACKGROUND_PRIMARY]};
  gap: ${Spacing(2.5)};
`;

const ArrowImg = styled.img`
  align-self: baseline;
`;

const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: ${COLOR_DATA[COLOR_ENUM.BACKGROUND_PRIMARY]};
  padding: ${Spacing(25)} ${Spacing(25)} ${Spacing(12.75)} ${Spacing(25)};
  margin: 0 auto;
  min-height: 530px;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.MENU]}px;
  width: 100%;
  max-width: ${Spacing(350)};

  @media screen and (max-width: 760px) {
    padding: 0;
    margin: 100px auto;
  }
`;

const AboutBlock = styled.div`
  display: grid;
  gap: ${Spacing(8)};
  text-align: center;
`;

const AboutText = styled.div`
  display: grid;
  gap: ${Spacing(6)};
`;

const AboutHeadTitle = styled(TextElem)`
  color: ${COLOR_DATA[COLOR_ENUM.TEXT_PRIMARY]};
`;

const HeadTitle = styled.div`
  display: grid;
  gap: ${Spacing(3)};
  font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.MAIN_HEAD_TITLE]}px;
  font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.MEDIUM]}px;
  margin-top: -83px;
`;

const HeadTitlePrimary = styled.p`
  display: flex;
  justify-content: center;
  font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.LOGO]}px;
  font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.SEMI_BOLD]};
  color: ${COLOR_DATA[COLOR_ENUM.TEXT_PRIMARY]};
`;

const QuestionBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;
  align-items: center;
`;

const QuestionTextGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(3)};
`;

const QuestionChildBlock = styled.div`
  display: flex;
  padding: 20px;
  border-radius: 13px;
  border: 1px solid white;
  justify-content: space-between;
  background-color: ${COLOR_DATA[COLOR_ENUM.WHITE]};

  :hover {
    cursor: pointer;
    border: 1px solid ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]};
  }

  :active {
    border: 1px solid blue;
    background-color: #e7f1ff;
  }
`;

const QuestionTextMain = styled(TextElem)`
  display: flex;
  justify-content: flex-start;
  font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.HEADING]}px;
  font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.MEDIUM]};
  line-height: 30px;
  text-align: left;
  color: ${COLOR_DATA[COLOR_ENUM.TEXT_PRIMARY]};
`;

const QuestionTextSecond = styled(TextElem)`
  display: flex;
  justify-content: flex-start;
  font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.MAIN]}px;
  font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.REGULAR]};
  line-height: 30px;
  text-align: left;
  color: ${COLOR_DATA[COLOR_ENUM.TEXT_PRIMARY]};
`;

const HeadTitleHighlight = styled.span`
  display: flex;
  justify-content: center;
  font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.MAIN]}px;
  font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.MEDIUM]}px;
  color: ${COLOR_DATA[COLOR_ENUM.TEXT_SECONDARY]};
`;
