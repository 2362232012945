import React, { useState } from 'react';
import styled from 'styled-components';
import { FormikValues } from 'formik';

import { ModalElem } from '../../../common/modal';
import { SelectElem } from '../../../common/select';
import { FORM_VALUE_ENUM } from '../constant';
import { Spacing } from '../../../theme';
import { ButtonElem } from '../../../common/button';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
  SIZE_FONT_WEIGHT_DATA,
  SIZE_FONT_WEIGHT_ENUM,
} from '../../../theme/size';
import { DatePickerElem } from '../../../common/date-picker';
import { COLOR_DATA, COLOR_ENUM } from '../../../theme/color';
import { FormElem } from '../../../common/form';
import { TextElem } from '../../../common/text';
import { TooltipElem } from '../../../common/tooltip';
import infoSvg from '../../../asset/svg/button/info-circle.svg';

export const ModalTimeChange: React.FC<{
  isFieldError: Function;
  getFieldError: Function;
  getFieldValue: Function;
  setFieldValue: Function;
  setValue: Function;
  formik: FormikValues;
  isSubmitDisabled: Function;
  timeOption: {
    label: string;
    value: string;
  }[];
  isFieldDisabled: boolean;
  isTimeLoading: boolean;
  isChangeTimeDisabled: boolean;
  isRessignDisabled: Function;
}> = ({
  isFieldError,
  getFieldError,
  setFieldValue,
  setValue,
  formik,
  isSubmitDisabled,
  timeOption,
  isTimeLoading,
  isFieldDisabled,
  isChangeTimeDisabled,
  getFieldValue,
  isRessignDisabled,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    formik.resetForm();
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <ButtonElemStyled
        type="button"
        disabled={isChangeTimeDisabled || isRessignDisabled()}
        onClick={handleOpen}
        // tid="CONSULTATION.USER.REASSIGN"
      >
        <TextElem tid="CONSULTATION.USER.REASSIGN" />
        <TooltipElem
          tid="CONSULTATION.USER.REASSIGN_TOOLTIP_ADMIN"
          color={COLOR_DATA[COLOR_ENUM.TEXT_FOURTH]}
        >
          <img src={infoSvg} alt="tooltip icon" />
        </TooltipElem>
      </ButtonElemStyled>
      <ModalElemStyled onClose={handleClose} open={isOpen}>
        <FormElem onSubmit={formik.handleSubmit}>
          <Double>
            <StyledDataPicker
              // isDisabled={isFieldDisabled()}
              name={FORM_VALUE_ENUM.DATE}
              title="ORDER.ITEM.REGISTRATION.DATE"
              placeholder="ORDER.ITEM.REGISTRATION.DATE_PLACEHOLDER"
              handleChange={(e) => {
                if (e instanceof Date) {
                  const formattedDate = e.toLocaleDateString('en-CA');

                  formik.setFieldValue(FORM_VALUE_ENUM.DATE, formattedDate);
                } else {
                  formik.setFieldValue(FORM_VALUE_ENUM.DATE, '');
                }
              }}
            />

            <StyledSelect
              isDisabled={isTimeLoading || !getFieldValue(FORM_VALUE_ENUM.DATE)}
              isLoading={isTimeLoading}
              placeholder="ORDER.ITEM.REGISTRATION.TIME_PLACEHOLDER"
              name={FORM_VALUE_ENUM.TIME}
              onChange={setFieldValue}
              options={timeOption}
              title="ORDER.ITEM.REGISTRATION.TIME"
              errorMessage={getFieldError(FORM_VALUE_ENUM.TIME)}
              error={isFieldError(FORM_VALUE_ENUM.TIME)}
              value={setValue(timeOption, FORM_VALUE_ENUM.TIME)}
            />
          </Double>
          <ButtonElem
            disabled={isSubmitDisabled()}
            type="submit"
            onClick={() => {
              formik.handleSubmit();
              setIsOpen(false);
            }}
            tid="CONSULTATION.USER.REASSIGN"
          />
        </FormElem>
      </ModalElemStyled>
    </>
  );
};

const StyledDataPicker = styled(DatePickerElem)`
  height: 54px;
`;

const StyledSelect = styled(SelectElem)`
  & .Select__control {
    input {
      color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PLACEHOLDER]} !important;
      font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.REGULAR]};
      font-size: 16px;
    }
  }

  & .Select__indicator:not(.Select__clear-indicator) {
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]};
  }
  & .Select_loading {
    right: 50px;
  }
`;

const ModalElemStyled = styled(ModalElem)`
  ::part(content) {
    padding: ${Spacing(6)};
    overflow: visible !important;
  }
`;

const ButtonElemStyled = styled(ButtonElem)`
  padding: ${Spacing(5)} ${Spacing(7)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;

  background-color: ${({ theme }) => theme[COLOR_ENUM.DEFAULT_OPACITY]};
  span {
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.MAIN]}px !important;
    font-weight: ${SIZE_FONT_WEIGHT_DATA[
      SIZE_FONT_WEIGHT_ENUM.MEDIUM
    ]} !important;
  }
`;

const Double = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${Spacing(2)};
  margin-bottom: ${Spacing(6)};
  width: 100%;
`;
